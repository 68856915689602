exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooking-gas-delivery-solution-js": () => import("./../../../src/pages/cooking-gas-delivery-solution.js" /* webpackChunkName: "component---src-pages-cooking-gas-delivery-solution-js" */),
  "component---src-pages-fuel-delivery-solution-js": () => import("./../../../src/pages/fuel-delivery-solution.js" /* webpackChunkName: "component---src-pages-fuel-delivery-solution-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-on-demand-fuel-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-on-demand-fuel-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-on-demand-fuel-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-on-demand-fuel-delivery-solution-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-an-on-demand-fuel-delivery-solution-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-on-demand-fuel-delivery-solution-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-on-demand-cooking-gas-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-on-demand-cooking-gas-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-on-demand-cooking-gas-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-on-demand-mobile-petrol-pump-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/complete-guide-on-demand-mobile-petrol-pump-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-on-demand-mobile-petrol-pump-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-corporate-fuel-delivery-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/corporate-fuel-delivery-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-corporate-fuel-delivery-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-to-develop-fuel-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-to-develop-fuel-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-to-develop-fuel-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-create-profitable-delivery-route-planner-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/create-profitable-delivery-route-planner/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-create-profitable-delivery-route-planner-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-develop-on-demand-fuel-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/develop-on-demand-fuel-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-develop-on-demand-fuel-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-to-know-about-on-demand-fuel-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/everything-you-need-to-know-about-on-demand-fuel-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-to-know-about-on-demand-fuel-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-features-of-fuel-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/features-of-fuel-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-features-of-fuel-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-features-of-on-demand-cooking-gas-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/features-of-on-demand-cooking-gas-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-features-of-on-demand-cooking-gas-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-fuel-delivery-africa-market-trends-regulations-challenges-opportunities-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/fuel-delivery-africa-market-trends-regulations-challenges-opportunities/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-fuel-delivery-africa-market-trends-regulations-challenges-opportunities-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-generate-better-revenues-in-fuel-delivery-business-with-gasswift-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/generate-better-revenues-in-fuel-delivery-business-with-gasswift/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-generate-better-revenues-in-fuel-delivery-business-with-gasswift-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-generate-revenues-using-fuel-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/generate-revenues-using-fuel-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-generate-revenues-using-fuel-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-covid-19-has-hiked-the-gas-deliveries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-covid19-has-hiked-the-gas-deliveries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-covid-19-has-hiked-the-gas-deliveries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-customer-loyalty-in-fuel-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importance-of-customer-loyalty-in-fuel-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-customer-loyalty-in-fuel-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-customer-loyalty-in-gas-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importance-of-customer-loyalty-in-gas-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-customer-loyalty-in-gas-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-value-added-services-for-fuel-delivery-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importance-value-added-services-for-fuel-delivery-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-value-added-services-for-fuel-delivery-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-cooking-gas-delivery-any-time-any-where-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-cooking-gas-delivery-any-time-any-where/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-cooking-gas-delivery-any-time-any-where-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-questions-to-ask-before-switching-to-fuel-delivery-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/questions-to-ask-before-switching-to-fuel-delivery-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-questions-to-ask-before-switching-to-fuel-delivery-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-convenience-in-on-demand-fuel-delivery-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/rise-of-convenience-in-on-demand-fuel-delivery/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-convenience-in-on-demand-fuel-delivery-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-start-fuel-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/start-fuel-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-start-fuel-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-fuel-delivery-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-fuel-delivery-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-fuel-delivery-trends-index-mdx" */)
}

